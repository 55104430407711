.App {
  background: #F1F0EB;
  min-height: 100vh;
  margin: 0;
  padding-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.container {
  max-width: 900px;
  margin: auto;
  padding: 30px;
}

h1 {
  font-family: 'Lora';
  font-size: 3.5rem;
  font-weight: 700;
  color: #261e13;
  line-height: 3.5rem;
}

h1 span {
  color: #FFA800;
  font-size: 3rem;
}

.me {
  max-width: 350px;
  width: 100%;
  mix-blend-mode: multiply;
}

.intro {
  display: flex;
  align-items: center;
  font-family: Inter;
  line-height: 1.6rem;
}

.intro-child:nth-child(1) {
  flex: 0 0 60%;
}

.link {
  text-decoration: none;
  font-weight: 600;
  color: #000;
  /* background-color: #F2952544; */
  border-bottom: 2px dashed #d36e28;
}


.social {
  padding: 20px;
  padding-bottom: 15px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.social:hover {
  background-color: #E7E4DC;
  border-radius: 4px;
  transform: scale(1.1);
}

.social:nth-child(1) {
  margin-left: -20px;
}



@media screen and (max-width: 616px) {
  .intro {
    flex-direction: column;
    text-align: center;
  }

  .intro-child:nth-child(2) {
    order: 0;
  }

  .me {
    max-width: 300px;
  }
  .intro-child:nth-child(1) {
    order: 1;
  }
}

@media screen and (max-width: 337px) {
.social img {
  width: 30px;
}
}